/**
 * @module MyLocationIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import HouseIcon from '../ui/house-icon';
import './MyLocationIcon.scss';

const MyLocationIcon = () => {
  return (
    <div className="align-center icon-chip paragraph_small">
      <HouseIcon color="#009ECC" />
      <span className="icon-text">My Location</span>
    </div>
  );
};

export default MyLocationIcon;
