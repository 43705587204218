// eslint-disable-next-line no-unused-vars
/**
 * @module ServingApp
 */
import React from 'react';
import { callSegmentIdentify } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getQueryParams } from '@lifechurch/web-tools-io/dist/utils/helpers/queryParams';
import useGeoLocation from '@lifechurch/web-tools-io/dist/hooks/useGeoLocation';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import ContactInfo from './ContactInfo';
import BasicInfo from './BasicInfo';
import Location from './Locations';
import MinistryTeam from './MinistryTeam';
import ThankYou from './ThankYou';
import { loadSegment } from '../../../lib';
import getGuidFromValue from '../helpers/getGuidFromValue';

const initialValues = {
  birth_date: '',
  campus: '',
  defaultCampus: '',
  email: '',
  first_name: '',
  last_name: '',
  opportunity: '',
  person_alias_id: '',
  phone: '',
};

/**
 * Represents the Serving App component for Multi-step Serving Form.
 *
 * @param {object} props - The component props object.
 * @param {Array} props.activeSteps - Array of active steps.
 * @param {Array} props.campuses - Array of campuses.
 * @param {object} [props.userInfoOverride] - Optional user info data object override to use for testing.
 *
 * @returns {React.ReactElement} The ServingApp component.
 */
function ServingApp({ activeSteps, campuses, userInfoOverride }) {
  const { geolocationData } = useGeoLocation();
  const userInUS = geolocationData?.country_code.toUpperCase() === 'US';
  const [activeStep, setActiveStep] = React.useState(activeSteps);
  const [userInfo, setUserInfo] = React.useState(
    userInfoOverride ?? initialValues,
  );

  const prefillInformation = () => {
    const userQueryData = {};
    const getFirstName = getQueryParams('first_name');
    const getLastName = getQueryParams('last_name');
    const getDOB = getQueryParams('birth_date');
    const getEmail = getQueryParams('email');
    const getPhone = getQueryParams('phone');

    // Support for query param and local storage retrieval of Rock profile id.
    let getPersonAliasId = getQueryParams('person_alias_id');
    try {
      if (
        typeof getPersonAliasId !== 'string' &&
        window.localStorage.getItem('user_profile')
      ) {
        const localStorageUserProfile = JSON.parse(
          window.localStorage.getItem('user_profile'),
        );
        getPersonAliasId =
          localStorageUserProfile[
            'https://www.life.church/rock_person_alias_id'
          ];
      }
    } catch (error) {
      /* istanbul ignore next */
      Log.error(error);
    }

    // Support for multiple query param keys for the following params.
    let getCampus = getQueryParams('campus');
    let getOpportunity = getQueryParams('opportunity');
    if (typeof getCampus !== 'string') {
      getCampus = getQueryParams('location');
    }
    if (typeof getOpportunity !== 'string') {
      getOpportunity = getQueryParams('team');
    }

    // Get the Guid values for attributes with human-readable value options.
    const campusGuid = getGuidFromValue({ campuses, campusSlug: getCampus });
    const opportunityGuid = getGuidFromValue({
      campuses,
      opportunitySlug: getOpportunity,
    });

    // Ignoring these here, as test coverage is handled in individual views.
    /* istanbul ignore next */
    if (userInUS) {
      userQueryData.campus = campusGuid || userInfoOverride?.campus || '';
    } else {
      const LCOCampus = campuses.find((campus) => campus.ShortCode === 'INT');
      userQueryData.campus = LCOCampus?.Guid || userInfoOverride?.campus || '';
    }
    /* istanbul ignore next */
    if (opportunityGuid) {
      userQueryData.opportunity = opportunityGuid;
    }

    // Apply query param value to userQueryData, if legitimate value found.
    if (typeof getFirstName === 'string') {
      userQueryData.first_name = getFirstName;
    }
    if (typeof getLastName === 'string') {
      userQueryData.last_name = getLastName;
    }
    if (typeof getDOB === 'string') {
      userQueryData.birth_date = getDOB;
    }
    if (typeof getEmail === 'string') {
      userQueryData.email = getEmail;
    }
    if (typeof getPhone === 'string') {
      userQueryData.phone = getPhone;
    }
    // Note: Type is number when gleaned from localStorage, string from query.
    if (['number', 'string'].includes(typeof getPersonAliasId)) {
      userQueryData.person_alias_id = getPersonAliasId;
      /* istanbul ignore next */
      callSegmentIdentify({ userId: getPersonAliasId });
    }
    setUserInfo({ ...userInfo, ...userQueryData });
  };

  /**
   * Single-run convenience effect to pre-fill queryParams in URL
   * and apply to userInfo.
   */
  React.useEffect(() => {
    prefillInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInUS]);

  /**
   * Single-run convenience effect to load Segment analytics when the proper
   * ENV vars are available.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (process.env.SEGMENT_URL) {
      /**
       * Fetch user from local storage to pass along to Segment for analytics.
       */
      let user;
      let pagePayload;
      try {
        user = window?.localStorage?.getItem('user_profile')
          ? JSON.parse(window?.localStorage?.getItem('user_profile'))
          : null;
        pagePayload = {
          category: document?.title?.split('|')?.[0]?.trim() || '',
          name: document?.title || '',
          properties: {
            logged_in: !!user,
            path: window?.location?.pathname,
            preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
            referrer: document?.referrer || null,
            title: document?.title || '',
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        };
      } catch (error) {
        Log.error(error);
      }
      loadSegment({ autoLoad: true, pagePayload });
    }
  }, []);

  const stepSequences = {
    default: ['BasicInfo', 'ContactInfo', 'MinistryTeam', 'ThankYou'],
    US: ['BasicInfo', 'ContactInfo', 'Location', 'MinistryTeam', 'ThankYou'],
  };

  const activeStepSequence = userInUS
    ? stepSequences.US
    : stepSequences.default;

  const sharedProps = {
    activeStep: activeStep + 1,
    handleActiveStep: setActiveStep,
    handleUserInfo: setUserInfo,
    userInfo,
    userInUS,
  };

  const currentStepComponentName = activeStepSequence[activeStep];

  const stepComponents = {
    BasicInfo,
    ContactInfo,
    Location,
    MinistryTeam,
    ThankYou,
  };

  const componentProps = {
    BasicInfo: { ...sharedProps, stringStepIndex: 1 },
    ContactInfo: { ...sharedProps, stringStepIndex: 2 },
    Location: { ...sharedProps, campuses, stringStepIndex: 3 },
    MinistryTeam: { ...sharedProps, campuses, stringStepIndex: 4 },
    ThankYou: {
      campuses,
      stringStepIndex: 5,
      userInfo,
    },
  };

  const CurrentStepComponent = stepComponents[currentStepComponentName];
  const currentStepComponentProps = componentProps[currentStepComponentName];

  return (
    <>
      <div className="multi-step-form">
        <div className="container">
          {CurrentStepComponent ? (
            <CurrentStepComponent {...currentStepComponentProps} />
          ) : /* istanbul ignore next*/ null}
        </div>
      </div>
    </>
  );
}
export default ServingApp;
