/**
 * @module Maps
 */

/**
 * Map of user data, mapping local attributes to POST-friendly attribute.
 */
export const USER_DATA_MAP = {
  birth_date: 'DateOfBirth',
  campus: 'CampusServing',
  email: 'ApplicantEmail',
  first_name: 'FirstName',
  last_name: 'LastName',
  opportunity: 'MinistryPreference',
  person_alias_id: '',
  phone: 'MobilePhone',
};

export const USER_DATA_ERROR_MAP = {
  ApplicantEmail: 'Email',
  CampusServing: 'Campus Location',
  DateOfBirth: 'Date of Birth',
  FirstName: 'First Name',
  LastName: 'Last Name',
  MinistryPreference: 'Ministry Team',
  MobilePhone: 'Phone Number',
};
