/**
 * @module StepsIndicator
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './StepsIndicator.scss';

const StepsIndicator = ({ activeStep, userInUS }) => {
  const steps = userInUS ? [1, 2, 3, 4] : [1, 2, 3];
  return (
    <div className="display-flex flex-row gap-tighter justify-space-between pb-normal pt-normal pl-none pr-none">
      {steps.map((step) => (
        <div
          className={`${activeStep.includes(step) && 'active-step-indicator'}
          step-indicator`}
          key={`step-${step}`}
        />
      ))}
    </div>
  );
};

export default StepsIndicator;
