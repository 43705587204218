/**
 * @module StickyButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import ButtonItem from '../../../../components/ButtonItem/ButtonItem';
import './stickybutton.scss';

/**
 * Represents a button with class attributes to assign it a sticky position to the bottom of an element.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.disabled - Boolean flag denoting whether or not the component is disabled.
 * @param {Function} props.handleSubmit - Handler function for the button submit event.
 * @param {string} [props.id] - Optional id to attribute to the button.
 * @param {string} props.text - The text label for the button.
 *
 * @returns {React.ReactElement} The StickyButton component.
 */
const StickyButton = ({ disabled, handleSubmit, id, text }) => {
  return (
    <div className="button-wrapper bg-white display-flex flex-column p-normal justify-center align-center">
      <ButtonItem
        buttonSize="large"
        className="btn-banner"
        disabled={disabled}
        id={id}
        onClick={handleSubmit}
        style="btn-primary"
        text={text}
        type="submit"
      />
    </div>
  );
};
export default StickyButton;
