/* eslint-disable no-unused-vars */
/**
 * @module Index
 */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './multiStepFormsApp';

ReactDOM.hydrate(
  // eslint-disable-next-line react/jsx-props-no-spreading, no-underscore-dangle
  <App {...window.__INITIAL__DATA__} />,
  document.getElementById('root'),
);
