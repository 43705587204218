/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module ContactInfo
 */
import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import Input from '../../../../components/Form/Input/Input';
import { ACTIONS, EVENTS, SCREENS } from '../../../../helpers/constants';
import StepsIndicator from '../StepsIndicator/StepsIndicator';
import BackButton from '../../components/BackButton';
import StickyButton from '../../components/StickyButton';
import { STRINGS } from '../../helpers/strings';

const ContactInfo = ({
  activeStep,
  handleActiveStep,
  handleUserInfo,
  stringStepIndex,
  userInfo,
  userInUS,
}) => {
  const [formErrors, setFormErrors] = React.useState({});
  const strings = {
    ...STRINGS,
    step: STRINGS.steps[stringStepIndex],
  };

  /**
   * Handler function for back button click.
   *
   * @returns {Function} Handler function for active step setting.
   */
  const handleBack = () => {
    return handleActiveStep(activeStep - 2);
  };

  /**
   * Handler function for input change event.
   *
   * @param {Event} event - The change Event object.
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    handleUserInfo({ ...userInfo, [name]: value });
  };

  /**
   * Convenience function to check validity of form input values.
   *
   * @param {object} values - Object of input field values against which to validate.
   *
   * @returns {boolean} Boolean flag denoting whether or not the supplied values are all valid.
   */
  const isValid = (values) => {
    const emailRegEx = /^[\w.+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegEx = {
      intl: /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/, // NOSONAR
      original: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    };
    const errors = {};
    if (
      !values.email ||
      !emailRegEx.test(values.email) ||
      !values.email.includes('@')
    ) {
      errors.email = 'Please enter a valid email address.';
    }
    /**
     * Note: Ignore directive appropriate since regEx test should catch anything
     * less than or equal to 9 digits, and second conditional is failsafe and
     * otherwise would show as uncovered. Test coverages for too long, short,
     * and blank phone already exists, so this is 100% covered.
     */
    /* istanbul ignore next */
    if (!values.phone || !phoneRegEx.intl.test(values.phone)) {
      errors.phone = 'Please enter a valid phone number.';
    } else if (values.phone.length <= 9) {
      errors.phone = 'Phone Number must be 10 digits or more.';
    } else if (values.phone.length >= 20) {
      errors.phone = 'Phone Number must be less than 20 digits';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /**
   * Handler function for next button click.
   *
   * @param {Event} event - The Event object associated with the submit event.
   *
   * @returns {Function} Active step handler function invocation.
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValid(userInfo)) {
      return null;
    }
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Serving Form',
        component_url: null,
        label: event.currentTarget.textContent,
        logged_in: !!userInfo.person_alias_id,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        screen: SCREENS.multiStep.contactInfo,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: userInfo.person_alias_id,
      },
    });
    return handleActiveStep(activeStep);
  };

  /**
   * Single-run convenience effect to fire off Segment page view event.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    callSegmentPage({
      category: null,
      name: SCREENS.multiStep.contactInfo,
      properties: {},
    });
  }, []);

  /**
   * Single-run convenience effect to auto-focus first input field and add
   * keyboard events to input fields.
   */
  React.useEffect(() => {
    function addKeyboardListeners() {
      const inputs = document.querySelectorAll('input');
      const formButton = document.querySelector('button#id_btn_next');
      /* istanbul ignore next */
      if (inputs && formButton) {
        [...inputs].forEach((input) => {
          input.addEventListener('keypress', (event) => {
            if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
              formButton.click();
            }
          });
        });
      }
    }
    /* istanbul ignore next */
    function autoFocusFirstInput() {
      const input = document.querySelector('input');
      if (input) {
        input.focus();
      } else {
        setTimeout(autoFocusFirstInput, 250);
      }
    }
    autoFocusFirstInput();
    addKeyboardListeners();
  }, []);

  return (
    <section className="section display-flex flex-column">
      <div className="inner-section">
        <div className="header display-flex align-center">
          <BackButton className="back-button" onClick={handleBack} />
          <h1 className="text-group_header mb-none align-center">
            {`${activeStep}. ${strings.step.titles.step}`}
          </h1>
        </div>
        <StepsIndicator activeStep={[1, 2]} userInUS={userInUS} />
        <div>
          <Input
            className={formErrors.email && 'input-error'}
            data-testid="id_email"
            defaultValue={userInfo.email}
            error={formErrors.email}
            inputName="email"
            label={strings.step.labels.email}
            onChange={handleChange}
            placeholder=""
            type="email"
          />
          <Input
            className={formErrors.phone && 'input-error'}
            data-testid="id_phone"
            defaultValue={userInfo.phone}
            error={formErrors.phone}
            hint={strings.step.hints.phone}
            inputName="phone"
            label={strings.step.labels.phone}
            onChange={handleChange}
            placeholder=""
            type="text"
          />
        </div>
      </div>
      <StickyButton
        handleSubmit={handleSubmit}
        id="id_btn_next"
        text={strings.general.next}
        type="submit"
      />
    </section>
  );
};
export default ContactInfo;
