/**
 * @module MultiStepFormsApp
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { AuthProvider } from '@lifechurch/web-tools-io/dist/context/AuthContext';
import { GeoLocationProvider } from '@lifechurch/web-tools-io/dist/context/GeoLocationContext';
import { ModalProvider } from '../../context/ModalContext';
import ErrorScreen from './Container/ErrorScreen';
import ServingApp from './Container/ServingApp';

import './styles.scss';

/**
 * Represents the main App element for the MultiStep Forms.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The MultiStepForms App component.
 */
export default function App(props) {
  if (props?.error) {
    return (
      <div className="multi-step-form">
        <div className="container">
          <ErrorScreen />
        </div>
      </div>
    );
  }

  return (
    <AuthProvider>
      <GeoLocationProvider>
        <ModalProvider>
          <ServingApp activeSteps={0} campuses={props?.campuses} />
        </ModalProvider>
      </GeoLocationProvider>
    </AuthProvider>
  );
}
