/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module HouseIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a HouseIcon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The House Icon element.
 */
export default function HouseIcon(
  /* istanbul ignore next */ { color = '#000000' } = { color: '#000000' },
) {
  return (
    <div className="icon" data-stroke-color={color} data-testid="house-icon">
      <svg
        fill="none"
        height="18"
        viewBox="0 0 18 18"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 6.975L9 2.25L15 6.975V14.4C15 14.758 14.8595 15.1014 14.6095 15.3546C14.3594 15.6078 14.0203 15.75 13.6667 15.75H4.33333C3.97971 15.75 3.64057 15.6078 3.39052 15.3546C3.14048 15.1014 3 14.758 3 14.4V6.975Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
        <path
          d="M6.75 15.75V9H11.25V15.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
      </svg>
    </div>
  );
}
