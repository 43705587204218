/**
 * @module ErrorScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import Button from '../../../../components/ButtonItem/ButtonItem';
import { ACTIONS, EVENTS, SCREENS } from '../../../../helpers/constants';
import { STRINGS } from '../../helpers/strings';

/**
 * Represents an error screen view with general messaging and connect with team button.
 *
 * @returns {React.ReactElement} The ErrorScreen component.
 */
const ErrorScreen = () => {
  const strings = {
    ...STRINGS,
  };

  /**
   * Handler function for button click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleButtonClick(event) {
    let user;
    try {
      user = window?.localStorage?.getItem('user_profile')
        ? JSON.parse(window?.localStorage?.getItem('user_profile'))
        : null;
    } catch (error) {
      Log.error(error);
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Serving Form',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event.currentTarget.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        screen: SCREENS.multiStep.errorScreen,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <section className="section error-screen full-screen container display-flex flex-column text-center justify-center">
      <div className="inner-section align-center justify-center display-flex flex-column">
        <h1 className="text-title mb-normal">
          {strings.errorScreen.titles.main}
        </h1>
        <p className="text-paragraph_normal line-height-normal mb-normal">
          {strings.errorScreen.messages.main}
        </p>
        <Button
          buttonSize="large"
          onClick={handleButtonClick}
          style="btn-secondary"
          text="Connect With Our Team"
          url="/nextsteps/question/"
        />
      </div>
    </section>
  );
};

export default ErrorScreen;
