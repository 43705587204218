/**
 * @module Strings
 * @description Contains string value constant values for strings used across the Multi-Step form. This helps avoid hard-coding strings in each component file.
 */
export const STRINGS = {
  backgroundSource: {
    id: '449F6653-126C-4D11-A7BF-4CB1983CE552',
  },
  errorScreen: {
    messages: {
      main: 'Please refresh and try again. If you still have trouble, please reach out so we can help you take your next step toward serving.',
    },
    titles: {
      main: 'Oops! There was a problem.',
    },
  },
  general: {
    back: 'Back',
    connectWithOurTeam: 'Connect With Our Team',
    continue: 'Continue',
    next: 'Next',
    submitForm: 'Submit Form',
  },
  steps: {
    1: {
      labels: {
        dateOfBirth: 'Date of Birth*',
        firstName: 'First Name*',
        lastName: 'Last Name*',
      },
      titles: {
        main: "I'm interested in serving.",
        step: 'Basic Info',
      },
    },
    2: {
      hints: {
        phone:
          'If you live outside of the United States, please include your country code.',
      },
      labels: {
        email: 'Email*',
        phone: 'Phone*',
      },
      titles: {
        step: 'Contact Info',
      },
    },
    3: {
      titles: {
        question: 'At which location would you like to serve?*',
        step: 'Location',
      },
    },
    4: {
      errors: {
        formErrors:
          'Oops! There was a problem submitting your form. Please go back and enter valid values for:',
        formSubmit:
          'Oops! There was a problem with the form submission. Please try again.',
        noCampus:
          'Oops! There was a problem loading the ministry team options.',
        noMinistryTeams:
          "There aren't any ministry teams to choose from at this time. Please reach out so we can help you get connected at this Life.Church location.",
      },
      prompts: {
        noCampusStepBack:
          'Please click the back button to return to the previous step and select the location you want to serve with.',
      },
      titles: {
        question: 'Which team do you want to serve with?*',
        step: 'Ministry Team',
      },
    },
    5: {
      formNames: {
        backgroundCheck: 'Background Check',
      },
      labels: {
        startBackgroundCheck: 'Start Background Check',
      },
      messages: {
        main: 'A volunteer or pastor from your location will reach out via phone or email.',
        nextStepBGC: 'Your next step is to complete a background check.',
      },
      titles: {
        main: 'Your serving form was submitted!',
      },
    },
  },
};
