/**
 * @module getGuidFromValue
 */

/**
 * Helper method to get the Guid of the campus and campus opportunity.
 *
 * @param {object} params - The params object.
 * @param {Array} params.campuses - An array of campus objects.
 * @param {string} [params.campusSlug] - Optional slug value of the campus for which to search.
 * @param {string} [params.opportunitySlug] - Optional slug value of the campus opportunity for which to search.
 *
 * @returns {string | null | undefined} - The Guid value associated with the specified campus or opportunity, null for invalid parameters, and undefined for campus or opportunity values not found.
 */
export default function getGuidFromValue({
  campuses,
  campusSlug,
  opportunitySlug,
}) {
  if (!campuses) {
    return null;
  }

  if (campusSlug && typeof campusSlug === 'string') {
    // Make sure to match against either short code or GUID value.
    const campusObject = campuses.find(
      (campus) =>
        campus?.ShortCode?.toLowerCase() === campusSlug.toLowerCase() ||
        campus?.Guid?.toLowerCase() === campusSlug.toLowerCase(),
    );
    return campusObject?.Guid;
  }

  if (opportunitySlug && typeof opportunitySlug === 'string') {
    const getCampusOpportunity = () => {
      for (let i = 0; i < campuses.length; i += 1) {
        const campusOpportunities = campuses[i]?.AvailableOpportunities;
        const normalizedOpportunities = [];
        campusOpportunities?.forEach((opportunity) => {
          const newOpportunity = opportunity;
          const lowercaseSlugs = [];
          opportunity?.Slugs?.forEach((slug) => {
            lowercaseSlugs.push(slug.toLowerCase());
          });
          newOpportunity.Slugs = lowercaseSlugs;
          normalizedOpportunities.push(newOpportunity);
        });
        // Make sure to match against either slug value or GUID (Value).
        const opportunityObject = normalizedOpportunities?.find(
          (opportunity) =>
            opportunity?.Slugs?.includes(opportunitySlug.toLowerCase()) ||
            opportunity?.Value?.toLowerCase() === opportunitySlug.toLowerCase(),
        );

        if (opportunityObject) {
          return opportunityObject;
        }
      }
      return undefined;
    };

    const campusOpportunity = getCampusOpportunity();
    return campusOpportunity?.Value;
  }

  return null;
}
